import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import {
  SellHero,
  SellLandingInfo,
  AboutOptions,
  SinglePropertyAdvice,
} from 'components/sections';
import { TripleImageSection } from 'components/blocks';
import sellPageMotion from 'motion/sell.js';

import { ViewportEnterAnimation } from 'components/ui';
import { functionSinglePropertyAdvice } from 'motion/sell.js';
import { functionAboutAccordion } from 'motion/about.js';
import { tripleImageSectionScroll} from 'motion/buy.js';

const ctaClick = () => {
  if (typeof window !== 'undefined') {
    window.openInstantValuation && window.openInstantValuation();
  }
}

const SellPage = ({ data }) => {
  const {
    pages: { propertySearchForm, sell },
  } = data;
    const {seo } = sell;

    const  img1 = {src:sell.pitch.second.img1, height:314, width: 456};
    const  img2 = {src:sell.pitch.second.img2, height:386, width: 346};
    const  img3 = {src:sell.pitch.second.img3, height:649, width: 416};

  return (
    <Layout onVisible={sellPageMotion}>
      <SEO title={seo.title} description={seo.meta} keywords={seo.keywords} />
      <SellHero
        content={sell.landing}
        propertySearchForm={propertySearchForm}
      />

      <SellLandingInfo content={sell.pitch.main} />

      <ViewportEnterAnimation offset={100} animation={tripleImageSectionScroll}>
        <TripleImageSection
          content={sell.pitch.second}
          buttonAction={() => console.log('lets discuss')}
          imageBack={img1}
          imageMiddle={img2}
          imageFront={img3}

          imageBackalt={sell.pitch.second.img1alt}
          imageMiddlealt={sell.pitch.second.img2alt}                     
          imageFrontalt={sell.pitch.second.img3alt}
        />
      </ViewportEnterAnimation>
      <ViewportEnterAnimation offset={100} animation={functionAboutAccordion}>
        <AboutOptions name="sell-page-faq" data={sell.faq}  />
      </ViewportEnterAnimation>
      <ViewportEnterAnimation offset={100} animation={functionSinglePropertyAdvice}>
        <SinglePropertyAdvice onClick={ctaClick} content={sell.callToAction} />
      </ViewportEnterAnimation>
    </Layout>
  );
};

export const query = graphql`
  query SellPage {
    pages {
      propertySearchForm {
        title
        offerTypes {
          lettings
          sales
        }
        defaultCost {
          min
          max
        }
        form {
          labels {
            area
            apartment
            bedrooms
            includeNewHomes
            searchBtn
          }
          selects {
            apartment {
              value
              text
            }
            bedrooms {
              value
              text
            }
          }
        }
      }
      sell {
        pageTitle
        landing {
          title
          bodyText
          img
        }
        pitch {
          main {
            img
            title
            paragraph1
            paragraph2
            callToAction
          }
          second {
            title
            paragraph1
            paragraph2
            callToAction
            img1
            img2
            img3
            img1alt
            img2alt
            img3alt
          }
        }
        callToAction {
          title
          subtitle
          btn
          img
        }
        developments {
          title
          description
        }
        faq {
          title
          questions {
            question
            answer
          }
        }
        seo{
          title
          keywords
          meta
        }
      }
    }
    
  }
`;

export default SellPage;
